import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import VuetifyMoney from "vuetify-money";

import Auth from "./components/auth/Auth";
import ListaClientes from "./components/clientes/Lista";
import Dashboard from "./components/dashboard/Dashboard";
import Faturas from "./components/faturas/Faturas";

import NumAbbr from "number-abbreviate";

Vue.use(VueRouter);
Vue.use(VuetifyMoney);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "Auth",
      component: Auth,
    },
    {
      path: "/faturas/:asaas_id_revenda",
      name: "Faturas",
      component: Faturas,
    },
    {
      path: "/dashboard/",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/lista/",
      name: "ListaClientes",
      component: ListaClientes,
    },
    {
      path: "*",
      component: () => import("./components/PaginaNaoEncontrada404"),
    },
  ],
});

Vue.config.productionTip = false;

Vue.mixin({
  data: function () {
    return {
      get versao() {
        return "v1.0.24";
      },
      get isRevenda() {
        let { host } = window.location;

        return !/painel\.gamadelivery\.app/g.test(host);
      },
      get valorRevenda() {
        return 40;
      },
      get valorNfce() {
        return 0.11;
      },
      get valorNfe() {
        return 0.11;
      },
      get valorGamaZap() {
        return 0;
      },
      get valorGamaZapCampanhas() {
        return 10;
      },
      get valorConsultoriaEstrategica() {
        return 40;
      },
      get valorRobo() {
        return 15;
      },
      get isDesenvolvimento() {
        return process.env.NODE_ENV === "development";
      },
      get url() {
        let {host} = window.location;

        return this.isDesenvolvimento
          ? ".gamadelivery.app"
          : host.replace("painel", "");
      },
      get dominio() {
        var parts = window.location.host.split('.');
        parts.shift();

        return this.isDesenvolvimento
          ? ".gamadelivery.app"
          : '.' + parts.join('.');
      }
    };
  },
});

Vue.filter("formatNumber", (value) => {
  var numAbbr = new NumAbbr();
  return numAbbr.abbreviate(value, 2);
});

Vue.filter("capitalize", (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
